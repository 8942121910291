import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Splash from "./pages/Splash";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
import Login from './pages/Login';
import Buy from './pages/Buy';
import Success from './pages/success';


const App = () => {
    return (

        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Splash} />
                    <Route path={`${process.env.PUBLIC_URL + "/login"}`} exact component={Login} />
                    <Route path={`${process.env.PUBLIC_URL + "/buy"}`} exact component={Buy} />
                    <Route path={`${process.env.PUBLIC_URL + "/success"}`} exact component={Success} />
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
