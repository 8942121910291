import React, { useContext, useEffect, useRef, useState } from 'react'
import SEO from "../common/SEO";
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from "../common/footer/ScrollTop";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import { FiMenu } from "react-icons/fi";
import Logo from "../elements/logo/Logo";
import { LanguageContext } from '../LanguageContext';
import { layoutContentAr } from "../layoutContent_ar";
import { layoutContentEn } from "../layoutContent_en";
import Darkmode from '../common/header/Darkmode';
import MobileMenu from '../common/header/MobileMenu';
import axios from 'axios';
import { BASE_URL } from '../config';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import useStickyHeader from '../common/header/useStickyHeader';

const Success = () => {

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const { language, setLanguage, direction, setDirection } = useContext(LanguageContext);
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);

    if (localStorage.getItem('access_token') == null) window.location.replace('/login');

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

    var url = new URL(window.location.href);
    var paymentId = url.searchParams.get("paymentId");

    axios.get(`${BASE_URL}/api/verify-payment?paymentId=${paymentId}`).then((res) => {
        console.log(res.data)
    })

    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`

    useEffect(() => {
        axios.get(`${BASE_URL}/api/content`).then(res => {
            console.log(res.data);
            const root = document.documentElement;
            
            if(res.data.mainInfo && res.data.mainInfo.color) {
                root.style.setProperty('--color-secondary', res.data.mainInfo?.color);
                root.style.setProperty('--color-primary', res.data.mainInfo?.color);
            }
        });
    }, []);

    return (
        <>
            <SEO title="Success" />
            <main dir={direction} className="page-wrapper">
                <header className={`rn-header header-default ${headerClasses}`}>
                    <div className="container position-relative">
                        <div className="row align-items-center row--0">
                            <div className="col-lg-3 col-md-6 col-4">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                />
                            </div>
                            <div className="col-lg-9 col-md-6 col-8 position-static">
                                <div className="header-right">
                                    <nav className="mainmenu-nav d-none d-lg-block">
                                        <ul className="mainmenu">
                                            <li className="has-droupdown"><Link spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_language : layoutContentAr.Menu_language}</Link>
                                                <ul className="submenu">
                                                    <li><a role="button" onClick={() => { setLanguage('EN'); setDirection('ltr') }}>English</a></li>
                                                    <li><a role="button" onClick={() => { setLanguage('AR'); setDirection('rtl') }}>العربيّة</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                        <div className="hamberger">
                                            <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                        </div>
                                    </div>
                                    <Darkmode />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />

                <br /><br /><br />

                <Element name="sucess" className="element" id="success" >
                    <div className="rn-demo-area rn-section-gap" id="demo">
                        <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={language == 'EN' ? layoutContentEn.Buy_success_subtitle : layoutContentAr.Buy_success_subtitle}
                                        title={language == 'EN' ? layoutContentEn.Buy_success_title : layoutContentAr.Buy_success_title}
                                        description=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>

                <CopyrightTwo />
            </main>
            <ScrollTop />
        </>
    )
}
export default Success;