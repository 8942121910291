import React, { useContext, useEffect, useRef, useState } from 'react'
import SEO from "../common/SEO";
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from "../common/footer/ScrollTop";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import { FiMenu } from "react-icons/fi";
import Logo from "../elements/logo/Logo";
import { LanguageContext } from '../LanguageContext';
import { layoutContentAr } from "../layoutContent_ar";
import { layoutContentEn } from "../layoutContent_en";
import Darkmode from '../common/header/Darkmode';
import MobileMenu from '../common/header/MobileMenu';
import useStickyHeader from '../common/header/useStickyHeader';
import { Nav } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../config';

const Login = () => {
    const { language, setLanguage, direction, setDirection } = useContext(LanguageContext);

    console.log('login language = ',language)
    const [ofcanvasShow, setOffcanvasShow] = useState(false);

    const [loginMessage, setLoginMessage] = useState("");
    const [registerMessage, setRegisterMessage] = useState("");
    const [displayLoginMessage, setDisplayLoginMessage] = useState("none")
    const [displayRegisterMessage, setDisplayRegisterMessage] = useState("none")
    const [color, setColor] = useState('green');

    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`

    if (localStorage.getItem('access_token') != null) {
        window.location.replace('/buy');
    }

    const handleLogin = (e) => {
        e.preventDefault();

        axios.post(`${BASE_URL}/api/sign-in`, {
            email: e.target[0].value,
            password: e.target[1].value,

        }).then((res) => {
            if (res.status == 204) {
                setColor("red")
                language == 'EN' ? setLoginMessage('Account not activated') : setLoginMessage('المعرّف غير مفعّل');
                setDisplayLoginMessage('block');
            } else {
                localStorage.setItem('access_token', res.data.access_token);
                window.location.replace('/buy');
            }

        }).catch(error => {
            if (error.response.status == 401) {
                setColor('red')
                language == 'EN' ? setLoginMessage('Login or password is wrong') : setLoginMessage('هناك خطأ في المعرف او كلمة السّر');
                setDisplayLoginMessage('block');
            }
        });

    };

    const handleRegister = (e) => {
        e.preventDefault();

        axios.post(`${BASE_URL}/api/sign-up`, {
            email: e.target[0].value,
            fullName: e.target[1].value,
            password: e.target[2].value,
        }).then((res) => {
            language == 'EN' ? setRegisterMessage('A confirmation mail has been sent') : setRegisterMessage('تم إرسال رسالة تأكيد بالبريد الإلكتروني');
            setColor('green');
            setDisplayRegisterMessage('block')
        }).catch((err) => {
            language == 'EN' ? setRegisterMessage('Email already exists') : setRegisterMessage('البريد الالكتروني مستعمل');
            setColor('red')
            setDisplayRegisterMessage('block')
        })
    };

    const switchToLogin = () => {
        setDisplayRegisterMessage('none')
        document.getElementById("login-form").style = "display:block"
        document.getElementById("register-form").style = "display:none"

    };

    const switchToRegister = () => {
        setDisplayLoginMessage('none');
        document.getElementById("register-form").style = "display:block"
        document.getElementById("login-form").style = "display:none"
    }

    useEffect(() => {
        axios.get(`${BASE_URL}/api/content`).then(res => {
            console.log(res.data);
            const root = document.documentElement;
            
            if(res.data.mainInfo && res.data.mainInfo.color) {
                root.style.setProperty('--color-secondary', res.data.mainInfo?.color);
                root.style.setProperty('--color-primary', res.data.mainInfo?.color);
            }
        });
    }, []);

    return (
        <>
            <SEO title="Login" />
            <main dir={direction} className="page-wrapper">
                <header className={`rn-header header-default ${headerClasses}`}>
                    <div className="container position-relative">
                        <div className="row align-items-center row--0">
                            <div className="col-lg-3 col-md-6 col-4">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                />
                            </div>
                            <div className="col-lg-9 col-md-6 col-8 position-static">
                                <div className="header-right">
                                    <nav className="mainmenu-nav d-none d-lg-block">
                                        <Nav />
                                    </nav>
                                    <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                        <div className="hamberger">
                                            <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                        </div>
                                    </div>
                                    <Darkmode />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />


                <br /><br /><br /><br /><br />
                <div className="contact-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitleTwo  dir={direction}
                                    Title={language == 'EN' ? "Please login to continue" : "الرجاء تسجيل الدخول للمتابعة"}
                                />
                            </div>
                        </div>
                        <div className="row row--30 mt--40">
                            <div className="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
                                <div id="message" style={{ display: displayLoginMessage, color: color }}>{loginMessage}</div>
                                <form id="login-form" action="" onSubmit={handleLogin}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={language == 'EN' ? "Email Address" : "البريد الإلكتروني"}
                                            required
                                        />
                                    </div>

                                    <div className="form-group hidden">
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder={language == 'EN' ? "Password" : "كلمة السّر"}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <button className="btn-default btn-large" >{language == 'EN' ? "Login ?" : "الدخول"}</button>
                                        &nbsp;&nbsp;
                                        <a href="#" onClick={() => switchToRegister()}>{language == 'EN' ? "Register ?" : "التسجيل ؟"}</a>
                                    </div>

                                </form>
                                <div id="message" style={{ display: displayRegisterMessage, color: color }}>{registerMessage}</div>
                                <form action="" id="register-form" style={{ display: 'none' }} onSubmit={handleRegister}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={language == 'EN' ? "Email Address" : "البريد الإلكتروني"}
                                            required
                                        />
                                    </div>

                                    <div className="form-group hidden">
                                        <input
                                            type="text"
                                            name="fullName"
                                            placeholder={language == 'EN' ? "Full Name" : "الاسم الكامل"}
                                            required
                                        />
                                    </div>

                                    <div className="form-group hidden">
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder={language == 'EN' ? "Password" : "كلمة السّر"}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button className="btn-default btn-large" >{language == 'EN' ? "Register" : "تسجيل"}</button>
                                        &nbsp;&nbsp;
                                        <a href="#" onClick={() => switchToLogin()}>{language == 'EN' ? "Login ?" : "الدّخول ؟"}</a>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyrightTwo />
            </main>
            <ScrollTop />
        </>
    )
}
export default Login;