import React, { useContext } from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { LanguageContext } from '../../LanguageContext';
import { layoutContentAr } from '../../layoutContent_ar';
import { layoutContentEn } from '../../layoutContent_en';

const Contact = ({contactInfo}) => {
    const { language, setLanguage } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Contact || Doob - React Business  Template" />
            {/* <Layout> */}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {language == 'EN' ? layoutContentEn.Contactus_subtitle : layoutContentAr.Contactus_subtitle}
                                        title = {language == 'EN' ? layoutContentEn.Contactus_title : layoutContentAr.Contactus_title}
                                        description = {language == 'EN' ? layoutContentEn.Cotnactus_description : layoutContentAr.Cotnactus_description}
                                    />
                                </div>
                            </div>
                            <ContactOne contactInfo={contactInfo} />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            {/* </Layout> */}
        </>
    )
}
export default Contact;