import React, { useContext } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './slider.css';
import { LanguageContext } from '../../LanguageContext';

const BrandList = [
    {
        image: './images/brand/brand-01.jpg'
    },
    {
        image: './images/brand/brand-02.jpg'
    },
    {
        image: './images/brand/brand-03.png'
    },
    {
        image: './images/brand/brand-04.png'
    },
    {
        image: './images/brand/brand-05.png'
    },
    {
        image: './images/brand/brand-06.png'
    },
    {
        image: './images/brand/brand-07.png'
    },
    {
        image: './images/brand/brand-08.jpeg'
    },
    {
        image: './images/brand/brand-09.png'
    },
    {
        image: './images/brand/brand-10.png'
    },
    {
        image: './images/brand/brand-11.png'
    },
    {
        image: './images/brand/brand-12.jpeg'
    },
]

const BrandOne = ({ brandStyle }) => {
    const { direction, setDirection } = useContext(LanguageContext);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: direction == 'ltr' ? 1 : -1,
        initialSlide: 5,
    };

    return (
        <Slider {...settings}>

            {BrandList.map((data, index) => (
                <a href="#"><img width="160" height="160" src={`${data.image}`} alt="Brand Image" /></a>
            ))}
        </Slider>
    )
}

export default BrandOne;
