export const layoutContentEn = {
	Menu_home: "Home",
	Menu_quicklook: "Quick look",
	Menu_ourservices: "Our services",
	Menu_ourclients: "Our clients",
	Menu_contactus: "Contact us",
	Menu_language: "Language",
	Menu_action_button:"Create a website",
	Home_main: "Create your own website with simple clicks ",
	Home_highlighted: "Customize & Edit your theme easily",
	Home_description: "You focus on your business plan and we design whatever you want.",
	QuickLook_subtitle: "A history of achievements and successes",
	QuickLook_title: "Quick look",
	QuickLook_description: "MicroBand was founded with a clear vision to be the first choice as a technology partner for customers and take responsibility for their mastery at a level they can be proud of.",
	Ourservices_subtitle: "What we can do for you",
	Ourservices_title: "Our services.",
	Ourservices_description: "Our services are comprehensive, sophisticated and always evolving.",
	Ourclients_subtitle: "We always provide the best, so our customers are always happy to deal with us",
	Ourclients_title: "Our awsome clients",
	Ourclients_description: "",
	Contactus_subtitle: "Contact Form",
	Contactus_title: "Our Contact Address Here.",
	Cotnactus_description: "",
	Contactus_phone: "Phone number",
	Contactus_email: "Our email address",
	Contactus_location: "Our location",
	Contactus_location_value: "3041 Alasaker St an Nuzha Riyadh",
	Contactus_form_name:"Name",
	Contactus_form_email:"Email address",
	Contactus_form_phone:"Phone number",
	Contactus_form_subject:"Subject",
	Contactus_form_message:"Message",
	Contactus_form_submit:"submit",
	Buy_domain_subtitle :"Select a Domain",
	Buy_domain_title : "Select a Domain",
	Buy_theme_title : "Select a Theme",
	Buy_success_title : "Purchase complete.",
	Buy_success_subtitle : "Application is being deployed. In order to create a https certificate we have to wait for about 1 hour until the DNS propagation.",
	Buy_table_search: "search",
	Buy_table_domain: "domain",
	Buy_table_availability: "availability",
	Buy_table_price: "price",
	Buy_table_select: "select",
	Terms: "Terms And Condition",
	Copyright:"Copyright All rights reserved © "
}