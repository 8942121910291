import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { BASE_URL } from '../../config';
import { LanguageContext } from '../../LanguageContext';
import { layoutContentEn } from '../../layoutContent_en';
import { layoutContentAr } from '../../layoutContent_ar';

function SearchBox(props) {
  const { language, setLanguage } = useContext(LanguageContext);

  const [domain, setDomain] = useState("");
  const [price, setPrice] = useState("");
  const [availability, setAvailability] = useState(false)
  const [otherExtansions, setOtherExtansions] = useState([])

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Perform search or other actions with the search term
    const searchTerm = event.target.elements.searchTerm.value;

    setDomain(searchTerm);

    axios.get(`${BASE_URL}/api/domain-availability?domain=${searchTerm}`).then((res) => {
      console.log('domainstatus',res.data)
      setAvailability(res.data.domainStatus)
      setPrice(res.data.price)
    })
  };

  const handleSelect = () => {
    props.selectedDomain(domain)
  }

  return (
    <div style={{width:600, marginRight:'auto', marginLeft:'auto'}}>
      <Form style={{ width: 600, display: 'flex', justifyContent: 'center', alignItems: 'center'}} inline onSubmit={handleFormSubmit}>
        <FormControl type="text" name="searchTerm" placeholder={language == 'EN' ? layoutContentEn.Buy_table_search :layoutContentAr.Buy_table_search} />
        <Button type="submit">{language == 'EN' ? layoutContentEn.Buy_table_search :layoutContentAr.Buy_table_search}</Button>
      </Form>
      <br /><br />
      <table style={{ width: 600, justifyContent: 'center', alignItems: 'center' }}>
        <thead style={{textAlign:'center'}}>
          <tr>
            <th>{language == 'EN' ? layoutContentEn.Buy_table_domain : layoutContentAr.Buy_table_domain}</th>
            <th>{language == 'EN' ? layoutContentEn.Buy_table_availability : layoutContentAr.Buy_table_availability}</th>
            <th>{language == 'EN' ? layoutContentEn.Buy_table_price : layoutContentAr.Buy_table_price}</th>
            <th>{language == 'EN' ? layoutContentEn.Buy_table_select : layoutContentAr.Buy_table_select}</th>
          </tr>
        </thead>
        <tbody style={{textAlign:'center'}}>
          <tr>
            <td style={{border: 'none'}}>{domain != "" ? domain : '---'}</td>
            <td style={{border: 'none'}}>{availability ? language == 'EN' ? 'Available' : 'متوفر' : language == 'EN' ? 'Not Available' : 'غير متوفر'}</td>
            <td style={{border: 'none'}}>{availability ? price +' $' : '---'}</td>
            <td style={{border: 'none'}}>{availability ? <Button onClick={() => handleSelect()}>{language == 'EN' ? layoutContentEn.Buy_table_select : layoutContentAr.Buy_table_select}</Button> : language == 'EN' ? 'Not Available' : 'غير متوفر'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SearchBox;