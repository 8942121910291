export const layoutContentAr = {
	Menu_home: "الرئيسية",
	Menu_quicklook: "نظرة سريعة",
	Menu_ourservices: "خدماتنا",
	Menu_ourclients: "عملاؤنا",
	Menu_contactus: "اتصل بنا",
	Menu_language: "اللّغة",
	Menu_action_button: "قم بإنشاء موقع",
	Home_main: "قم بإنشاء موقع الويب الخاص بك بنقرات بسيطة",
	Home_highlighted: "قم بتعديل و تخصيص التصميم بسهولة",
	Home_description: "أنت تركز على مشروعك ,نحن نخطط ونصمم لك ما تريد",
	QuickLook_subtitle: "تاريخ من الإنجازات والنجاحات",
	QuickLook_title: "نظرة سريعة",
	QuickLook_description: "تأسست  ميكروباند وفق رؤية واضحة هدفها أن تكون الخيار الأول كشريك تقني للعملاء وتتحمل مسؤولية الاتقان لهم على مستوى يفخرون به.",
	Ourservices_subtitle: "ما يمكننا فعله من أجلك",
	Ourservices_title: "خدماتنا",
	Ourservices_description: "خدماتنا شاملة ومتطورة ودائمة التطور",
	Ourclients_subtitle: "نحن نقدم الأفضل دائمًا ، لذلك يسعد عملاؤنا دائمًا بالتعامل معنا",
	Ourclients_title: "عملاؤنا",
	Ourclients_description: "",
	Contactus_subtitle: "سوف نرد عليك في أقرب وقت ممكن ، لأنك أول اهتمامنا",
	Contactus_title: "اتصل بنا",
	Cotnactus_description: "",
	Contactus_phone: "الهاتف",
	Contactus_email: "البريد الالكتروني",
	Contactus_location: "العنوان",
	Contactus_location_value: "الرياض النزهة شارع العساكر 3041",
	Contactus_form_name:"الاسم",
	Contactus_form_email:"البريد الالكتروني",
	Contactus_form_phone:"الهاتف",
	Contactus_form_subject:"الموضوع",
	Contactus_form_message:"الرّسالة",
	Contactus_form_submit:"إرسال",
	Buy_domain_subtitle :"اختر اسم موقع",
	Buy_domain_title : "اختر اسم موقع",
	Buy_theme_title : "اختر تصميم",
	Buy_success : "  يمكنك العثور على التطبيق قيد التشغيل على",
	Buy_success_title : "عمليّة الشراء تمّت بنجاح.",
	Buy_success_subtitle : "الموقع قيد النشر، يرجى الانتظار لحوالي ساعة لكي يتم نشر الDNS و صناعة certificate.",
	Buy_table_search: "ابحث",
	Buy_table_domain: "اسم الموقع",
	Buy_table_availability: "التوفر",
	Buy_table_price: "الثمن",
	Buy_table_select: "اختر",
	Terms: "شروط الاستخدام",
	Copyright: "كل الحقوق محفوضة © "
}