import React , {useContext, useState} from 'react';
import emailjs from 'emailjs-com';
import { LanguageContext } from '../../LanguageContext';
import { layoutContentEn } from '../../layoutContent_en';
import { layoutContentAr } from '../../layoutContent_ar';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    const { language, setLanguage } = useContext(LanguageContext);

    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e.target)
        emailjs
        .sendForm(
            'service_fqdg8rt', 
            'template_317scrw', 
            e.target, 
            'sBM6MRZ-HuuOubNtq'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder={language == 'EN' ? layoutContentEn.Contactus_form_name : layoutContentAr.Contactus_form_name}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder={language == 'EN' ? layoutContentEn.Contactus_form_email : layoutContentAr.Contactus_form_email}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder={language == 'EN' ? layoutContentEn.Contactus_form_phone : layoutContentAr.Contactus_form_phone}
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder={language == 'EN' ? layoutContentEn.Contactus_form_subject : layoutContentAr.Contactus_form_subject}
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder={language == 'EN' ? layoutContentEn.Contactus_form_message : layoutContentAr.Contactus_form_message}
                required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">{language == 'EN' ? layoutContentEn.Contactus_form_submit : layoutContentAr.Contactus_form_submit}</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
