import React, { useContext, useEffect, useState } from 'react';
// import {Link} from "react-router-dom";
import { Link } from 'react-scroll'
import { LanguageContext } from '../../LanguageContext';
import { layoutContentAr } from '../../layoutContent_ar';
import { layoutContentEn } from '../../layoutContent_en';


const Nav = () => {
    const { language, setLanguage, direction, setDirection } = useContext(LanguageContext);
    const [displaySignOut, setDisplaySignOut] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('access_token') != null) { setDisplaySignOut(true); }
    }, []);

    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link activeClass="active" to="home" spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_home : layoutContentAr.Menu_home}</Link>
            </li>

            <li><Link activeClass="active" to="quickLook" spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_quicklook : layoutContentAr.Menu_quicklook}</Link></li>

            <li className="with-megamenu"><Link activeClass="active" to="ourServices" spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_ourservices : layoutContentAr.Menu_ourservices}</Link>
            </li>

            <li className="has-droupdown"><Link activeClass="active" to="ourClients" spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_ourclients : layoutContentAr.Menu_ourclients}</Link>
            </li>

            <li className="has-droupdown"><Link activeClass="active" to="contactUs" spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_contactus : layoutContentAr.Menu_contactus}</Link></li>

            <li className="has-droupdown"><Link spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_language : layoutContentAr.Menu_language}</Link>
                <ul className="submenu">
                    <li><a role="button" onClick={() => {
                        setLanguage('EN'); setDirection('ltr'); localStorage.setItem('language', 'EN');
                        localStorage.setItem('direction', 'ltr');
                    }}>English</a></li>
                    <li><a role="button" onClick={() => {
                        setLanguage('AR'); setDirection('rtl'); localStorage.setItem('language', 'AR');
                        localStorage.setItem('direction', 'rtl');
                    }}>العربيّة</a></li>
                </ul>
            </li>
            {displaySignOut ?
                <li className="has-droupdown"><a onClick={() => { localStorage.removeItem('access_token'); setDisplaySignOut(false) }} spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? 'Sign out' : 'خروج'}</a></li>
                :
                <></>
            }

        </ul>
    )
}
export default Nav;
