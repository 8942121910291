import React, { useContext, useEffect, useRef, useState } from 'react'
import SEO from "../common/SEO";
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from "../common/footer/ScrollTop";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import { FiMenu } from "react-icons/fi";
import Logo from "../elements/logo/Logo";
import { LanguageContext } from '../LanguageContext';
import { layoutContentAr } from "../layoutContent_ar";
import { layoutContentEn } from "../layoutContent_en";
import Darkmode from '../common/header/Darkmode';
import MobileMenu from '../common/header/MobileMenu';
import useStickyHeader from '../common/header/useStickyHeader';
import { Button, Form, FormControl, InputGroup, Nav } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../config';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import SearchBox from '../elements/search/searchBox';


const Demo = [
    {
        Url: "",
        Image: "../images/demo/teza.png",
        Imagelight: "../images/demo/teza.png",
        Title: "teza",
        badge: "",
    },
    {
        Url: "",
        Image: "../images/demo/mazaa.png",
        Imagelight: "../images/demo/mazaa.png",
        Title: "mazaa",
        badge: "",
    }
]

const Buy = () => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const { language, setLanguage, direction, setDirection } = useContext(LanguageContext);

    const [domain, setDomain] = useState("");
    const [price, setPrice] = useState("");
    const [theme, setTheme] = useState("");

    if (localStorage.getItem('access_token') == null) window.location.replace('/login');

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`

    const selectDomain = (domain) => {
        setDomain(domain)
        document.getElementById("selectTheme").style = "display:block"
        document.getElementById("selectDomain").style = "display:none"
    }

    const selectTheme = (title) => {
        setTheme(title)
        axios.get(`${BASE_URL}/api/execute-payment?theme=${title}&domain=${domain}`).then((res) => {
            if(res.data.success) {
                window.location.replace(res.data.PaymentURL);
            }
        })
    }
    useEffect(() => {
        axios.get(`${BASE_URL}/api/content`).then(res => {
            console.log(res.data);
            const root = document.documentElement;
            
            if(res.data.mainInfo && res.data.mainInfo.color) {
                root.style.setProperty('--color-secondary', res.data.mainInfo?.color);
                root.style.setProperty('--color-primary', res.data.mainInfo?.color);
            }
        });
    }, []);
    return (
        <>
            <SEO title="Buy" />
            <main dir={direction} className="page-wrapper">
                <header className={`rn-header header-default ${headerClasses}`}>
                    <div className="container position-relative">
                        <div className="row align-items-center row--0">
                            <div className="col-lg-3 col-md-6 col-4">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                />
                            </div>
                            <div className="col-lg-9 col-md-6 col-8 position-static">
                                <div className="header-right">
                                    <nav className="mainmenu-nav d-none d-lg-block">
                                        <ul className="mainmenu">
                                            <li className="has-droupdown"><Link spy={true} smooth={true} offset={50} duration={500}>{language == 'EN' ? layoutContentEn.Menu_language : layoutContentAr.Menu_language}</Link>
                                                <ul className="submenu">
                                                    <li><a role="button" onClick={() => { setLanguage('EN'); setDirection('ltr') }}>English</a></li>
                                                    <li><a role="button" onClick={() => { setLanguage('AR'); setDirection('rtl') }}>العربيّة</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                        <div className="hamberger">
                                            <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                        </div>
                                    </div>
                                    <Darkmode />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />


                <br /><br /><br />
                <Element name="selectTheme" className="element" id="selectTheme" style={{ display: 'none' }}>
                    <h4><center>{language == 'EN' ? layoutContentEn.Buy_theme_title : layoutContentAr.Buy_theme_title}</center></h4>
                    <div className="rn-demo-area rn-section-gap" id="demo">
                        <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                            <div className="row row--30">
                                {Demo.map((data, index) => (
                                     <div className="col col-sm mt--40" key={index}>
                                     <div className="single-demo">
                                             <div className="thumbnail">
                                                 <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                 <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                                 {data.badge ? <span className="label-badge">{data.badge}</span> : ''}
                                             </div>
                                             <h4 className="title"><Link onClick={() => selectTheme(data.Title)}>{data.Title}</Link> </h4>
                                     </div>
                                 </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Element>

                <Element name="selectDomain" className="element" id="selectDomain" >
                    <div className="rn-demo-area rn-section-gap" id="demo">
                        <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={language == 'EN' ? layoutContentEn.Buy_domain_subtitle : layoutContentAr.Buy_domain_subtitle}
                                        title={language == 'EN' ? layoutContentEn.Buy_domain_title : layoutContentAr.Buy_domain_title}
                                        description=""
                                    />
                                </div>
                            </div>
                        </div>
                        <SearchBox selectedDomain={selectDomain} />
                    </div>
                </Element>

                <CopyrightTwo />
            </main>
            <ScrollTop />
        </>
    )
}
export default Buy;