import React, { useContext } from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import { LanguageContext } from '../../LanguageContext';
import { layoutContentAr } from '../../layoutContent_ar';
import { layoutContentEn } from '../../layoutContent_en';

const ContactOne = ({contactInfo}) => {
    const { language, setLanguage } = useContext(LanguageContext);

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{language == 'EN' ? layoutContentEn.Contactus_phone : layoutContentAr.Contactus_phone}</h4>
                                        <p><a href="tel:+966-531565156">{contactInfo?.phone}</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{language == 'EN' ? layoutContentEn.Contactus_email : layoutContentAr.Contactus_email}</h4>
                                        <p><a href={"mailto:"+contactInfo?.email}>{contactInfo?.email}</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{language == 'EN' ? layoutContentEn.Contactus_location : layoutContentAr.Contactus_location}</h4>
                                        <p>{language == 'EN' ? contactInfo?.addressEn : contactInfo?.addressAr}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOne;