import React from 'react';
import CalltoActionEight from "../../elements/calltoaction/CalltoActionEight";
import CopyrightTwo from "./CopyrightTwo";
import ScrollTop from "./ScrollTop";


const FooterThree = ({inputAndData, shortCuts, socialNetworks, contactInfo }) => {
    return (
        <>
            <div className="footer-style-2">
                <CopyrightTwo inputAndData={inputAndData} shortCuts={shortCuts} socialNetworks={socialNetworks} contactInfo={contactInfo} />
            </div>
            <ScrollTop />
        </>
    )
}
export default FooterThree;