import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  let initialLanguage = localStorage.getItem('language') == null ? 'AR': localStorage.getItem('language')
  let initialDir = localStorage.getItem('direction') == null ? 'rtl': localStorage.getItem('direction') ;

  const [language, setLanguage] = useState(initialLanguage)
  const [direction, setDirection] = useState(initialDir)

  return (
    <LanguageContext.Provider value={{ language, setLanguage, direction, setDirection }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;