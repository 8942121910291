import React, { useContext, useState } from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";
import { LanguageContext } from '../../LanguageContext';
import { layoutContentEn } from '../../layoutContent_en';
import { layoutContentAr } from '../../layoutContent_ar';
import { Modal } from 'antd';


const CopyrightTwo = ({ inputAndData, socialNetworks }) => {
    const { language, setLanguage } = useContext(LanguageContext);
    const [openLegacy, setOpenLegacy] = useState(false);

    const getIcon = (title) => {
        switch (title) {
            case 'Facebook':
                return <FiFacebook />;
            case 'Twitter':
                return <FiTwitter />;
            case 'Youtube':
                return <FiYoutube />;
            case 'LinkedIn':
                return <FiLinkedin />;
            case 'Threads':
                return <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"/></svg>;
            case 'Instagram':
                return <FiInstagram />;
            default:
                return <FiFacebook />;
        }
    }
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a onClick={() => setOpenLegacy(true)}>{language == 'EN' ? layoutContentEn.Terms : layoutContentAr.Terms}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                {socialNetworks?.map((item) => (
                                    <li>
                                        <a target="_blank" href={item.link}>
                                            {getIcon(item.titleEn)}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">{language == 'EN' ? layoutContentEn.Copyright + new Date().getFullYear() + ' MicroBand.' : 'مايكروباند ' + layoutContentAr.Copyright + new Date().getFullYear()} </p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title={language == 'EN' ? layoutContentEn.Legacy : layoutContentAr.Legacy}
                visible={openLegacy}
                onOk={() => setOpenLegacy(false)}
                onCancel={() => setOpenLegacy(false)}
                footer={false}
            >
                <p> {language == 'EN' ? inputAndData?.legacyEn : inputAndData?.legacyAr}</p>
            </Modal>

        </div>

    )
}
export default CopyrightTwo;