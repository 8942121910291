import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from '../common/footer/FooterThree';
import { Element } from 'react-scroll'
import Contact from '../elements/contact/Contact';
import BrandOne from "../elements/brand/BrandOne";
import ServiceTwo from '../elements/service/ServiceTwo';
import { LanguageContext } from '../LanguageContext';
import { layoutContentAr } from '../layoutContent_ar';
import { layoutContentEn } from '../layoutContent_en';
import axios from 'axios';
import { BASE_URL } from '../config';

const Demo = [
    {
        Url: "/buy",
        Image: "../images/demo/teza.png",
        Imagelight: "../images/demo/teza.png",
        Title: "teza",
        badge: "",
    },
    {
        Url: "/buy",
        Image: "../images/demo/mazaa.png",
        Imagelight: "../images/demo/mazaa.png",
        Title: "mazaa",
        badge: "",
    }
]

const InnerPages = [
    {
        id: "1",
        Url: "/blog-grid",
        Image: "./images/demo/blog-grid.png",
        Imagelight: "./images/demo/blog-grid-light.png",
        Title: "Blog Grid"
    },
    {
        id: "2",
        Url: "/blog-grid-sidebar",
        Image: "./images/demo/blog-grid-sidebar.png",
        Imagelight: "./images/demo/blog-grid-sidebar-light.png",
        Title: "Blog Grid Sidebar"
    },
    {
        id: "3",
        Url: "/blog-list-view",
        Image: "./images/demo/blog-list-view.png",
        Imagelight: "./images/demo/blog-list-view-light.png",
        Title: "Blog List View"
    },
]

const Splash = () => {

    const { language, setLanguage, direction, setDirection } = useContext(LanguageContext);

    const [content, setContent] = useState({})

    document.dir = direction

    useEffect(() => {
        axios.get(`${BASE_URL}/api/content`).then(res => {
            setContent(res.data);
            console.log(res.data);
            const root = document.documentElement;
            
            if(res.data.mainInfo && res.data.mainInfo.color) {
                root.style.setProperty('--color-secondary', res.data.mainInfo?.color);
                root.style.setProperty('--color-primary', res.data.mainInfo?.color);
            }
        });
    }, []);

    
    return (
        <>
            <SEO title="MicroBand" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne logo={content.mainInfo ? content.mainInfo.logo : ""} btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <Element name="home" className="element" id="home">
                    <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner text-center">
                                        <h1 className="title display-two">
                                            {language == 'EN' ? layoutContentEn.Home_main : layoutContentAr.Home_main}
                                            <br />
                                            <span className="theme-gradient">{language == 'EN' ? layoutContentEn.Home_highlighted : layoutContentAr.Home_highlighted}</span>
                                            <br />
                                        </h1>
                                        <p className="description">{language == 'EN' ? layoutContentEn.Home_description : layoutContentAr.Home_description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Our Demo  */}
                <Element name="quickLook" className="element" id="quickLook">
                    <div className="rn-demo-area rn-section-gap" id="demo">
                        <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={language == 'EN' ? layoutContentEn.QuickLook_subtitle : layoutContentAr.QuickLook_subtitle}
                                        title={language == 'EN' ? layoutContentEn.QuickLook_title : layoutContentAr.QuickLook_title}
                                        description={language == 'EN' ? content?.inputAndData?.quickLookEn : content?.inputAndData?.quickLookAr}
                                    />
                                </div>
                            </div>
                            <div className='container'>
                                <div className="row row--30">
                                    {Demo.map((data, index) => (
                                        <div className="col col-sm mt--40" key={index}>
                                            <div className="single-demo">
                                                <Link to={`${data.Url}`}>
                                                    <div className="thumbnail">
                                                        <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                        <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                                        {data.badge ? <span className="label-badge">{data.badge}</span> : ''}
                                                    </div>
                                                </Link>
                                                <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link> </h4>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                {/* End Our Demo  */}

                <Separator />


                {/* Start Service Area  */}
                <Element name="ourServices" className="element" id="ourServices">
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={language == 'EN' ? layoutContentEn.Ourservices_subtitle : layoutContentAr.Ourservices_subtitle}
                                        title={language == 'EN' ? layoutContentEn.Ourservices_title : layoutContentAr.Ourservices_title}
                                        description={language == 'EN' ? content.inputAndData?.whoWeAreEn : content.inputAndData?.whoWeAreAr}
                                    />
                                </div>
                            </div>
                            <ServiceTwo
                                services={content.services ? content.services : []}
                                cardStyle="card-style-1"
                                textAlign="text-start"
                            />
                        </div>
                    </div>
                </Element>
                {/* End Service Area  */}

                <Separator />
                <Element name="ourClients" className="element" id="ourClients">
                    <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={language == 'EN' ? layoutContentEn.Ourclients_subtitle : layoutContentAr.Ourclients_subtitle}
                                        title={language == 'EN' ? layoutContentEn.Ourclients_title : layoutContentAr.Ourclients_title}
                                        description={language == 'EN' ? layoutContentEn.Ourclients_description : layoutContentAr.Ourclients_description}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                <Separator />


                <Element name="contactUs" className="element" id="contactUs">
                    <Contact contactInfo={content.contactInfo ? content.contactInfo : {}} />
                </Element>
                {/* End Inner Pages  */}
                <FooterThree inputAndData={content.inputAndData ? content.inputAndData : {}} shortCuts={content.shortCuts ? content.shortCuts : []} socialNetworks={ content.socialNetwork ? content.socialNetwork : []} contactInfo={content.contactInfo ? content.contactInfo : {}} />
            </main>

        </>
    )
}

export default Splash;