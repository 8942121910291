import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FiAirplay } from "react-icons/fi";
import { AiOutlineCloudServer, AiOutlineAppstore } from "react-icons/ai"
import { MdContentPaste } from "react-icons/md"
import { LanguageContext } from '../../LanguageContext';

const ServiceList = [
    {
        image: <FiAirplay size={50} />,
        title: 'it infrastructure support',
        description: 'we work to provide the best solutions and ideas to provide a flexible, adaptive, modern and cost-effective infrastructure that meets your needs.'
    },
    {
        image: <AiOutlineCloudServer size={50} />,
        title: 'Cloud solutions',
        description: 'the continuous development of cloud services requires the work team to keep pace with this by adopting and harnessing new technologies for our customers.'
    },
    {
        image: <AiOutlineAppstore size={50} />,
        title: 'Software and applications',
        description: 'the analysis team is creative in understanding the requirements of software solutions to be translated by the ideas of our designers and achieved with the most appropriate tools and languages in the hands of our programmers.'
    },
    {
        image: <MdContentPaste size={50} />,
        title: 'E-content industry',
        description: 'we tell the story, portray the vision, direct and produce a narrative that matches your passion with MicroBand\'s content creation team.'
    }
]
const ServiceTwo = ({services, textAlign, cardStyle }) => {
    const { language, setLanguage } = useContext(LanguageContext);
    return (
        <div className="row row--15 service-wrapper">
            {services.map((val, i) => (
                <div className="col-3 mx-auto" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        {val.image}
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{ __html: language == 'EN' ? val.titleEn : val.titleAr }}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: language == 'EN' ? val.descriptionEn : val.descriptionAr }}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                
            ))}
        </div>
    )
}
export default ServiceTwo;